<template>
  <div>
    <el-card shadow="never" class="card-custom-all">
      <p class="name-head">แก้ไขเกณฑ์ในการพิจารณา</p>
      <el-row :gutter="30">
        <el-col :span="24" :md="12" class="box-set-tooltip">
          <el-form-item
            label="ขนาดธุรกิจ"
            class="is-required"
            prop="checkCreditSizeS"
          >
            <el-checkbox v-model="formData.creditSizeS" @change="changeStep"
              >Size S</el-checkbox
            >
            <el-checkbox v-model="formData.creditSizeM" @change="changeStep"
              >Size M</el-checkbox
            >
            <el-checkbox v-model="formData.creditSizeL" @change="changeStep"
              >Size L</el-checkbox
            >
          </el-form-item>

          <el-tooltip
            class="item font-14 custom-tooltip"
            effect="dark"
            placement="bottom-start"
          >
            <div slot="content">
              <span>
                ลักษณะธุรกิจ <br />
                1. ธุรกิจการผลิต คำนวนขนาดธุรกิจจาก สสว. ใหม่ ใช้เกณฑ์ <br />
                - SIZE S 0 - 100 ล้านบาท <br />
                - SIZE M มากกว่า 100 - 500 ล้านบาท <br />
                - SIZE L มากกว่า 500 ล้านบาทขึ้นไป <br />
                2. ธุรกิจบริการ และ ธุรกิจแบบซื้อมาขายไป คำนวนขนาดธุรกิจจาก สสว.
                ใหม่ ใช้เกณฑ์ <br />
                - SIZE S 0 - 50 ล้านบาท <br />
                - SIZE M มากกว่า 50 - 300 ล้านบาท <br />
                - SIZE L มากกว่า 300 ล้านบาทขึ้นไป
              </span>
            </div>
            <i class="fas fa-question-circle"></i>
          </el-tooltip>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="เกรด" class="is-required" prop="gradeValid">
            <el-select
              id="seleteGrade"
              multiple
              filterable
              clearable
              default-first-option
              v-model="formData.grades"
              placeholder="เลือกเกรด"
              popper-class="custom-select"
              @change="selectGrade"
            >
              <el-option
                v-for="item in optionsGradeByCredit"
                :key="item._id"
                :value="item._id"
                :label="item.gradeName"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.gradeName }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12" class="box-set-tooltip">
          <el-form-item label="ประเภทผู้ประกอบการ">
            <el-select
              clearable
              multiple
              filterable
              id="formDataOperatorType"
              v-model="formData.operatorType"
              placeholder="เลือกประเภทผู้ประกอบการ"
              @change="changeStep"
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsOperatorType"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.label }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-tooltip
            class="item font-14 custom-tooltip operatorType"
            effect="dark"
            placement="bottom-start"
          >
            <div slot="content">
              <span>
                ประเภทผู้ประกอบการ มี 3 ตัวเลือก <br />
                1. ผู้ส่งออก (ประเภทของผู้ประกอบการ: ผู้ส่งออก) <br />
                2. ผู้มีแผนที่จะส่งออก เลือก <br />
                - ประเภทของผู้ประกอบการ: ผู้ประกอบการค้าขายภายในประเทศ <br />
                - มีการผลิต หรือ ขายให้กับผู้ส่งออกโดยตรงหรือไม่: ไม่มีแผน
                <br />
                - ภายในปีนี้มีแผนจะเป็นผู้ส่งออกโดยตรงหรือไม่: มีแผน<br />
                3. ผู้ประกอบการผู้ผลิตเพื่อผู้ส่งออก เลือก<br />
                - ประเภทของผู้ประกอบการ: ผู้ประกอบการค้าขายภายในประเทศ<br />
                - มีการผลิต หรือ ขายให้กับผู้ส่งออกโดยตรงหรือไม่: มีแผน<br />
                - ภายในปีนี้มีแผนจะเป็นผู้ส่งออกโดยตรงหรือไม่: ไม่มีมีแผน<br />
              </span>
            </div>
            <i class="fas fa-question-circle"></i>
          </el-tooltip>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="ประเภทอุตสาหกรรม">
            <el-select
              clearable
              filterable
              id="formDataIndustryType"
              v-model="formData.cateIndustryType"
              placeholder="เลือกประเภทอุตสาหกรรม"
              @change="selectIndusty"
              multiple
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsIndusty"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.label }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="จำนวนปีที่จดทะเบียนมากกว่า">
            <el-select
              clearable
              filterable
              id="formDataRegisDate"
              v-model="formData.regisDate"
              placeholder="เลือกจำนวนปีที่จดทะเบียนมากกว่า"
            >
              <el-option
                v-for="item in optionsYearRegis"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="โครงการจากระบบ TERAK" prop="expTERAK">
            <el-select
              clearable
              filterable
              id="formDataProjectTERAK"
              v-model="formData.projectTERAK"
              placeholder="เลือกโครงการจากระบบ TERAK"
              @change="selectTERAK"
              multiple
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsRef"
                :key="item.id"
                :label="
                  item.expire
                    ? item.projectName + ' (หมดอายุ)'
                    : item.projectName
                "
                :value="item.id"
              >
                <div class="is-flex ai-center">
                  <div
                    class="circle-select"
                    :class="{ 'color-danger': item.expire }"
                  ></div>
                  <div :class="{ 'color-danger': item.expire }">
                    {{ item.projectName }}
                    <span v-if="item.expire" class="mg-l-7"> (หมดอายุ)</span>
                  </div>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios";
import { TERAK } from "@/service/axios";
import { mapState } from "vuex";
import crypto from "crypto";
import moment from "moment";
const algorithm = "aes-256-ctr";
const secretKey = "vwPH9sdmpnVjrTIQCc7qSXs11lWhzFrT";
const iv = crypto.randomBytes(16);
export default {
  props: ["formData"],
  mounted() {
    this.getGrade();
    this.getProjectByTerak("CFOE2021");
    this.checkAll();
  },
  watch: {
    formData() {
      this.getGrade();
      this.getProjectByTerak("CFOE2021");
      this.checkAll();
    },
  },
  data() {
    return {
      optionsGradeByCredit: [],
      optionsRef: [],
      optionsIndusty: [],
      constSelect: [],
      constSelect1: [],
      constSelect2: [],
      optionsOperatorType: [
        {
          id: "1",
          label: "ผู้ส่งออก",
        },
        {
          id: "2",
          label: "ผู้มีแผนที่จะส่งออก",
        },
        {
          id: "3",
          label: "ผู้ประกอบการผู้ผลิตเพื่อผู้ส่งออก",
        },
      ],
      optionsYearRegis: [
        {
          value: 1,
          label: "1 ปี",
        },
        {
          value: 2,
          label: "2 ปี",
        },
        {
          value: 3,
          label: "3 ปี",
        },
        {
          value: 4,
          label: "4 ปี",
        },
        {
          value: 5,
          label: "5 ปี",
        },
        {
          value: 6,
          label: "6 ปี",
        },
        {
          value: 7,
          label: "7 ปี",
        },
        {
          value: 8,
          label: "8 ปี",
        },
        {
          value: 9,
          label: "9 ปี",
        },
        {
          value: 10,
          label: "10 ปี",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      optionsIndustryType: (state) => state.industryType,
    }),
  },
  methods: {
    checkAll() {
      this.optionsIndusty = JSON.parse(
        JSON.stringify(this.optionsIndustryType)
      );
      let industry = this.optionsIndusty.some((e) => e.id == "999");
      if (!industry) {
        this.optionsIndusty.unshift({
          id: Number("999"),
          label: "ทั้งหมด",
        });
      }

      if (
        this.formData.cateIndustryType.length ==
        this.optionsIndusty.length - 1
      ) {
        this.formData.cateIndustryType.unshift(999);
      }
      this.constSelect1 = JSON.parse(
        JSON.stringify(this.formData.cateIndustryType)
      );
    },
    changeStep() {
      this.$emit("stepNow", this.formData);
    },
    getGrade() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`criterion/grade/all`)
        .then((res) => {
          if (res.data.success) {
            this.optionsGradeByCredit = res.data.obj;
            this.optionsGradeByCredit.unshift({
              _id: Number("999"),
              gradeName: "ทั้งหมด",
            });

            if (
              this.formData.grades.length ==
              this.optionsGradeByCredit.length - 1
            ) {
              this.formData.grades.unshift(999);
            }
            this.constSelect = JSON.parse(JSON.stringify(this.formData.grades));
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    getProjectByTerak(text) {
      try {
        var ivstring = iv.toString("hex").slice(0, 16);
        const cipher = crypto.createCipheriv(algorithm, secretKey, ivstring);
        const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

        let obj = {
          iv: ivstring.toString("hex"),
          content: encrypted.toString("hex"),
        };
        TERAK.defaults.headers.common.Authorization = `Bearer ${obj.iv +
          ":" +
          obj.content}`;
        this.optionsRef = [];
        TERAK.get(`cfoe/refs`)
          .then((res) => {
            if (res.data.success) {
              res.data.obj.forEach((item) => {
                let expire = false;
                // let dateNow = moment(new Date()).format("yyyy-MM-DD");
                // let dateEnd = moment(item.endDate).format("yyyy-MM-DD");
                let index = this.formData.projectTERAK.findIndex(
                  (a) => a == item.id
                );
                // if (dateNow > dateEnd) {
                //   expire = true;
                // }
                !expire || index > -1
                  ? this.optionsRef.push({
                      ...item,
                      expire: expire,
                    })
                  : "";
              });
              this.optionsRef.unshift({
                id: Number("999"),
                expire: false,
                projectName: "ทั้งหมด",
              });

              if (
                this.formData.projectTERAK.length ==
                this.optionsRef.length - 1
              ) {
                this.formData.projectTERAK.unshift(999);
              }
              this.constSelect2 = JSON.parse(
                JSON.stringify(this.formData.projectTERAK)
              );
              this.$emit("optionsOut", this.optionsRef);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log("Error: encrypt", e);
        return false;
      }
    },
    selectGrade(val) {
      if (val[val.length - 1] == 999) {
        this.formData.grades = [];
        this.optionsGradeByCredit.forEach((gAll) => {
          this.formData.grades.push(gAll._id);
        });
        this.constSelect = JSON.parse(JSON.stringify(this.formData.grades));
      } else {
        if (val[0] === 999 && val.length < this.optionsGradeByCredit.length) {
          this.formData.grades.splice(0, 1);
          this.constSelect = JSON.parse(JSON.stringify(this.formData.grades));
        } else if (this.constSelect[0] === 999 && val[0] !== 999) {
          this.formData.grades = [];
          this.constSelect = JSON.parse(JSON.stringify(this.formData.grades));
        } else {
          let fn = this.constSelect.findIndex((a) => a === 999);
          if (val.length == this.optionsGradeByCredit.length - 1 && fn === -1) {
            this.formData.grades = [];
            this.optionsGradeByCredit.forEach((gAll) => {
              this.formData.grades.push(gAll._id);
            });
            this.constSelect = JSON.parse(JSON.stringify(this.formData.grades));
          }
        }
      }
      this.changeStep();
    },
    selectIndusty(val) {
      if (val[val.length - 1] == 999) {
        this.formData.cateIndustryType = [];
        this.optionsIndusty.forEach((gAll) => {
          this.formData.cateIndustryType.push(gAll.id);
        });
        this.constSelect1 = JSON.parse(
          JSON.stringify(this.formData.cateIndustryType)
        );
      } else {
        if (val[0] === 999 && val.length < this.optionsIndusty.length) {
          this.formData.cateIndustryType.splice(0, 1);
          this.constSelect1 = JSON.parse(
            JSON.stringify(this.formData.cateIndustryType)
          );
        } else if (this.constSelect1[0] === 999 && val[0] !== 999) {
          this.formData.cateIndustryType = [];
          this.constSelect1 = JSON.parse(
            JSON.stringify(this.formData.cateIndustryType)
          );
        } else {
          let fn = this.constSelect1.findIndex((a) => a === 999);
          if (val.length == this.optionsIndusty.length - 1 && fn === -1) {
            this.formData.cateIndustryType = [];
            this.optionsIndusty.forEach((gAll) => {
              this.formData.cateIndustryType.push(gAll.id);
            });
            this.constSelect1 = JSON.parse(
              JSON.stringify(this.formData.cateIndustryType)
            );
          }
        }
      }
      this.changeStep();
    },
    selectTERAK(val) {
      if (val[val.length - 1] == 999) {
        this.formData.projectTERAK = [];
        this.optionsRef.forEach((gAll) => {
          !gAll.expire ? this.formData.projectTERAK.push(gAll.id) : "";
        });
        this.constSelect2 = JSON.parse(
          JSON.stringify(this.formData.projectTERAK)
        );
      } else {
        if (val[0] === 999 && val.length < this.optionsRef.length) {
          this.formData.projectTERAK.splice(0, 1);
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.formData.projectTERAK)
          );
        } else if (this.constSelect2[0] === 999 && val[0] !== 999) {
          this.formData.projectTERAK = [];
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.formData.projectTERAK)
          );
        } else {
          let fn = this.constSelect2.findIndex((a) => a === 999);
          if (val.length == this.optionsRef.length - 1 && fn === -1) {
            this.formData.projectTERAK = [];
            this.optionsRef.forEach((gAll) => {
              !gAll ? this.formData.projectTERAK.push(gAll.id) : "";
            });
            this.constSelect2 = JSON.parse(
              JSON.stringify(this.formData.projectTERAK)
            );
          }
        }
      }
      this.changeStep();
    },
  },
};
</script>
