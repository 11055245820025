<template>
  <DefaultTemplate
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-name-page">
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/manage/credit' }"
            >จัดการข้อมูลสินเชื่อ</el-breadcrumb-item
          >
          <el-breadcrumb-item>แก้ไขข้อมูลสินเชื่อ</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="title mg-t-4">แก้ไขข้อมูลสินเชื่อ</p>
      </div>
    </div>
    <div v-loading="loading">
      <el-form
        label-position="top"
        :model="form"
        class="set-form"
        :rules="rules"
        ref="form"
        v-if="!loading"
      >
        <Step1
          @stepNow="formDataOut"
          @optionsOut="optionsRef"
          :formData="form"
        />
        <Step2
          @creditEdit="creditEdit"
          :formData="form"
          :optionsMajor="optionsMajor"
          :optionsProductName="optionsProductName"
          :loadingSave="loadingSave"
        />
      </el-form>
    </div>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import Step1 from "@/components/credit/edit/Step1";
import Step2 from "@/components/credit/edit/Step2";
import { HTTP } from "@/service/axios";
import { TERAK } from "@/service/axios";
import jwt from "jsonwebtoken";

export default {
  components: {
    DefaultTemplate,
    Step1,
    Step2,
  },
  computed: {
    _token() {
      return this.$store.state._token;
    },
  },
  data() {
    var validGrade = (rule, value, callback) => {
      if (this.form.grades.length == 0) {
        callback(new Error("กรุณาเลือกเกรด"));
      } else {
        callback();
      }
    };

    var validSize = (rule, value, callback) => {
      if (
        !this.form.creditSizeS &&
        !this.form.creditSizeM &&
        !this.form.creditSizeL
      ) {
        callback(new Error("กรุณาเลือกขนาดธุรกิจ"));
      } else {
        callback();
      }
    };
    var validDate = (rule, value, callback) => {
      if (this.form.creditStart == "" && this.form.creditEnd == "") {
        callback(new Error("กรุณาเลือกวันที่การให้สินเชื่อ"));
      } else {
        callback();
      }
    };
    var validProfit = (rule, value, callback) => {
      if (value == 0) {
        callback(new Error("กรุณากรอกความสามารถในการชำระดอกเบี้ย"));
      } else {
        callback();
      }
    };

    var validTERAK = (rule, value, callback) => {
      let isExpire = [];
      this.form.projectTERAK.forEach((row) => {
        let checkId = this.optionsRefs.find(
          (e) => e.expire === true && e.id == row
        );
        if (typeof checkId != "undefined") {
          isExpire.push(checkId);
        }
      });

      if (isExpire.length > 0) {
        callback(new Error("กรุณานำโครงการที่หมดอายุออก"));
      } else {
        callback();
      }
    };

    var validtitleOne = (rule, value, callback) => {
      if (this.form.statusOne && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleTwo = (rule, value, callback) => {
      if (this.form.statusTwo && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleThree = (rule, value, callback) => {
      if (this.form.statusThree && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleFour = (rule, value, callback) => {
      if (this.form.statusFour && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleFive = (rule, value, callback) => {
      if (this.form.statusFive && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleSix = (rule, value, callback) => {
      if (this.form.statusSix && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleSeven = (rule, value, callback) => {
      if (this.form.statusSeven && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleEight = (rule, value, callback) => {
      if (this.form.statusEight && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleNine = (rule, value, callback) => {
      if (this.form.statusNine && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    return {
      loadingData: true,
      optionsRefs: [],
      loadingSave: false,
      loading: true,
      form: {},
      idData: this.$route.params.id,
      rules: {
        majorMinor: [
          {
            required: true,
            message: "กรุณาเลือก Major - Minor",
            trigger: "change",
          },
        ],
        productProgramName: [
          {
            required: true,
            message: "กรุณาเลือก Product Program Code",
            trigger: "change",
          },
        ],
        expTERAK: [{ validator: validTERAK, trigger: ["change"] }],
        checkCreditSizeS: [{ validator: validSize, trigger: "change" }],
        gradeValid: [{ validator: validGrade, trigger: "change" }],
        titleOne: [{ validator: validtitleOne, trigger: ["blur", "change"] }],
        titleTwo: [{ validator: validtitleTwo, trigger: ["blur", "change"] }],
        titleThree: [
          { validator: validtitleThree, trigger: ["blur", "change"] },
        ],
        titleFour: [{ validator: validtitleFour, trigger: ["blur", "change"] }],
        titleFive: [{ validator: validtitleFive, trigger: ["blur", "change"] }],
        titleSix: [{ validator: validtitleSix, trigger: ["blur", "change"] }],
        titleSeven: [
          { validator: validtitleSeven, trigger: ["blur", "change"] },
        ],
        titleEight: [
          { validator: validtitleEight, trigger: ["blur", "change"] },
        ],
        titleNine: [{ validator: validtitleNine, trigger: ["blur", "change"] }],
        cateIndustryType: [
          {
            required: true,
            message: "กรุณาเลือกประเภทอุตสาหกรรม",
            trigger: "change",
          },
        ],
        projectTERAK: [
          {
            required: true,
            message: "กรุณาเลือกโครงการจากระบบ TERAK",
            trigger: "change",
          },
        ],
        creditProductId: [
          {
            required: true,
            message: "กรุณากรอก Credit Product ID",
            trigger: ["blur", "change"],
          },
        ],
        interestType: [
          {
            required: true,
            message: "กรุณาเลือกประเภทของสินเชื่อ",
            trigger: "change",
          },
        ],
        abilityProfit: [
          {
            required: true,
            message: "กรุณากรอกความสามารถในการชำระดอกเบี้ย",
            trigger: "blur",
          },
          { validator: validProfit, trigger: "blur" },
        ],

        creditName: [
          {
            required: true,
            message: "กรุณาเลือก Product Program Name",
            trigger: ["blur", "change"],
          },
        ],
        date: [{ validator: validDate, trigger: ["blur", "change"] }],
      },
      optionsMajor: [],
      optionsProductName: [],
    };
  },
  mounted() {
    this.getCredit();
    this.getMasterDataMajor();
    this.getProductName();
  },
  methods: {
    getMasterDataMajor() {
      HTTP.get(`crm/credit/master`)
        .then((res) => {
          if (res.data.success) {
            this.optionsMajor = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    getProductName() {
      HTTP.get(`crm/product/program/master`)
        .then((res) => {
          if (res.data.success) {
            this.optionsProductName = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    getCredit() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`credit/edit/${this.idData}`)
        .then((res) => {
          if (res.data.success) {
            if (res.data.obj.regisDate == 0) {
              res.data.obj.regisDate = "";
            }
            this.form = res.data.obj;
            this.form.creditSizeS =
              this.form.creditSizeS != true ? false : true;
            this.form.creditSizeM =
              this.form.creditSizeM != true ? false : true;
            this.form.creditSizeL =
              this.form.creditSizeL != true ? false : true;
            this.form.delImg = "";
            this.form.file = [];
            let setArrGrades = [];
            this.form.grades.map((e) => {
              setArrGrades.push(e.gradeId);
            });
            this.form = { ...this.form, grades: setArrGrades };
            this.loading = false;
            this.loadingData = false;
            setTimeout(() => {
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }, 500);
          }
        })
        .catch((e) => {
          this.loadingData = false;

          if (e.response.data.status == 401) {
            this.checkAuth();
          } else if (
            e.response.data.status == 404 ||
            e.response.data.status == 422
          ) {
            this.$message({
              message: "ไม่พบข้อมูลสินเชื่อ สินเชื่อ",
              type: "error",
              duration: 4000,
            });
            this.$router.push(`/manage/credit`);
          } else {
            this.alertCatchError(e);
          }
        });
    },
    async creditEdit(data) {
      this.form = data;
      this.$refs["form"].validate().then(async (valid) => {
        if (valid) {
          this.loadingSave = true;
          let resImg = "";
          if (this.form.file.length > 0) {
            if (typeof this.form.file[0].file != "undefined") {
              var formDataTERAK = new FormData();
              formDataTERAK.append("file", this.form.file[0].file.raw);
              var token = jwt.sign(
                { system: "CFOE2021" },
                "Y2weQEzYjje3LRDiwAUiX1U2r",
                { expiresIn: "5m" }
              );

              TERAK.defaults.headers.common.Authorization = token;
              const res = await TERAK.post(`cfoe/file`, formDataTERAK);
              if (res.data.success) {
                resImg = res.data.filename;
              }
            }
          }
          let grade = this.form.grades.findIndex((a) => a === 999);
          let cateIndustryType = this.form.cateIndustryType.findIndex(
            (a) => a === 999
          );
          let projectTERAK = this.form.projectTERAK.findIndex((a) => a === 999);

          if (grade > -1) {
            this.form.grades.splice(grade, 1);
          }
          if (cateIndustryType > -1) {
            this.form.cateIndustryType.splice(cateIndustryType, 1);
          }
          if (projectTERAK > -1) {
            this.form.projectTERAK.splice(projectTERAK, 1);
          }

          // let setMajor = this.optionsMajor.find(
          //   (e) => e._id == this.form.majorMinor
          // );

          let forGrade = [];
          let forOperatorType = [];
          let forCateIndustryType = [];
          let forProjectTERAK = [];

          this.form.grades.forEach((row) => {
            forGrade.push(row.toString());
          });
          this.form.operatorType.forEach((row) => {
            forOperatorType.push(row.toString());
          });
          this.form.cateIndustryType.forEach((row) => {
            forCateIndustryType.push(row.toString());
          });
          this.form.projectTERAK.forEach((row) => {
            forProjectTERAK.push(row.toString());
          });

          let obj = {
            sendToCH: this.form.sendToCH,
            regisDate: this.form.regisDate != '' ? this.form.regisDate : 0,
            operatorType: forOperatorType,
            creditName: this.form.creditName,
            cateIndustryType: forCateIndustryType,
            projectTERAK: forProjectTERAK,
            // creditProductId: this.form.creditProductId,
            creditSizeS: this.form.creditSizeS,
            creditSizeM: this.form.creditSizeM,
            creditSizeL: this.form.creditSizeL,
            interestType: this.form.interestType,
            // abilityProfit:
            //   this.form.creditSize == "L" || this.form.interestType != 2
            //     ? 0
            //     : this.form.abilityProfit,
            grade: forGrade,
            creditStart: this.form.creditStart,
            creditEnd: this.form.creditEnd,
            attributeProductService: this.form.attributeProductService,
            taxOrIncrease: this.form.taxOrIncrease,
            limitMaximum: this.form.limitMaximum,
            guarantee: this.form.guarantee,
            property: this.form.property,
            condition: this.form.condition,
            description: this.form.description,
            delImg: this.form.delImg != "" ? this.form.delImg : "",
            image: resImg == "" ? this.form.image : resImg,
            titleOne: this.form.titleOne,
            titleTwo: this.form.titleTwo,
            titleThree: this.form.titleThree,
            titleFour: this.form.titleFour,
            titleFive: this.form.titleFive,
            titleSix: this.form.titleSix,
            titleSeven: this.form.titleSeven,
            titleEight: this.form.titleEight,
            titleNine: this.form.titleNine,
            statusOne: this.form.statusOne,
            statusTwo: this.form.statusTwo,
            statusThree: this.form.statusThree,
            statusFour: this.form.statusFour,
            statusFive: this.form.statusFive,
            statusSix: this.form.statusSix,
            statusSeven: this.form.statusSeven,
            statusEight: this.form.statusEight,
            statusNine: this.form.statusNine,
            detailsEight: this.form.detailsEight,
            detailsNine: this.form.detailsNine,

            majorMinor: this.form.majorMinor,
            productProgramName: this.form.productProgramName,
          };

          HTTP.put(`credit/${this.idData}`, obj)
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "แก้ไขข้อมูลสินเชื่อสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/manage/credit`);
              } else {
                this.loadingSave = false;
                if (res.data.status == 204) {
                  this.$message({
                    message: "ชื่อข้อมูลสินเชื่อซ้ำ",
                    type: "error",
                    duration: 4000,
                  });
                } else if (res.data.status == 403) {
                  this.$store.commit("SET_PAGE_ACTIVE", null);
                  this.$router.push("/not-allowed");
                }
              }
            })
            .catch((e) => {
              this.loadingSave = false;

              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            });
        }
      });
    },

    optionsRef(data) {
      this.optionsRefs = data;
    },
    formDataOut(data) {
      this.form = data;
      // if (
      //   this.form.creditSize.toLowerCase() == "s" ||
      //   this.form.creditSize.toLowerCase() == "m"
      // ) {
      //   if (this.form.interestType != 1 && this.form.interestType != 2) {
      //     this.form.interestType = "";
      //   }
      // } else if (this.form.creditSize.toLowerCase() == "l") {
      //   if (this.form.interestType != 3 && this.form.interestType != 4) {
      //     this.form.interestType = "";
      //   }
      // }
    },
  },
};
</script>
